import React from 'react'
import { SpinnerSVG } from '../../components/Spinner'

type Props = {
  w?: string | number
  h: string | number
}

function ChartLoading({ w, h }: Props) {
  const style: React.CSSProperties = {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
    width: w === undefined ? '100%' : typeof w === 'string' ? w : `${w}rem`,
    height: typeof h === 'string' ? h : `${h}rem`
  }
  return (
    <div style={style}>
      <SpinnerSVG />
    </div>
  )
}

export default ChartLoading
