import React, { Fragment, useCallback } from 'react';
import { css } from '@linaria/core';
import { usePortfolio } from './PortfolioHooks';
import { ContentCard, List } from 'src/components/styles';
import { Expander, FlexRow } from 'src/composing/Flex';
import { IconButton } from 'src/composing/CSButton';
import { CSText, TextCaption, TextDisplaySmall } from 'src/composing/CSText';
import { SpacerH, SpacerV, Spacing } from 'src/composing/Spacing';
import { SkeletonWrapper } from 'src/components/SuspenseSkeleton';
import { useGetUserWatchlist } from '@commonstock/common/src/api/user';
import { Colors } from 'src/theme/constants';
import { ProfilePositionsSkeleton, SmallProfilePositionsSkeleton } from 'src/scopes/profile/Profile.skeleton';
import EmptyListItem from 'src/components/EmptyListItem';
import ListHeader from 'src/components/ListHeader';
import WatchlistItem, { ProfileWatchlistItem } from './WatchlistItem';
import { useUserProfile } from 'src/scopes/profile/ProfileHooks';
import { ProfileIndexProps } from 'pages/[username]';
import { useEphemeralModal } from 'src/scopes/modal/Modal';
import Tooltip from 'src/components/Tooltip';
import { Share } from 'src/theme/NewIcons';
import { WatchlistShareModal } from 'src/scopes/share/ShareModal';
import { UserProfile, useGetMyProfile, userProfileToHydratedProfile } from '@commonstock/common/src/api/profile';
import EditPost from '../post/EditPost';
import { useFlags } from '../feature-flags/useFlags';
import { formatOptionExpiration } from '@commonstock/common/src/utils/format';

function Watchlist({
  small
}: {
  small?: boolean;
}) {
  const {
    watchlist
  } = usePortfolio();
  return <List>
      <FlexRow alignCenter>
        <ListHeader title="Watchlist" greyHeader={!small} small={small} />
        <Expander />
        <ShareWatchlistButton />
        <SpacerH />
      </FlexRow>

      {watchlist.map(asset => <WatchlistItem key={asset.id} asset={asset} />)}

      {!watchlist.length && <EmptyListItem text={'Search for assets and add them to your watchlist.'} />}
    </List>;
}

type Props = {
  profile?: UserProfile;
  small?: boolean;
} & Pick<ProfileIndexProps, 'publicWatchlist' | 'publicProfile'>;

function EmptyList() {
  return <Spacing padding={[1, 0]}>
      <CSText block centered>
        User has no assets added to their watchlist
      </CSText>
    </Spacing>;
}

export function ProfileWatchlist({
  publicProfile,
  publicWatchlist,
  small
}: Props) {
  const {
    profile,
    isMe
  } = useUserProfile(publicProfile);
  const [watchlist,, watchlistPending] = useGetUserWatchlist({
    meta: {
      user_uuid: profile?.user_uuid || ''
    }
  }, {
    paused: !profile
  });
  const userWatchlist = watchlist || publicWatchlist;

  if (small) {
    return <SkeletonWrapper pending={Boolean(watchlistPending)} skeleton={<SmallProfilePositionsSkeleton />} failed={!watchlistPending && !watchlist?.length && <EmptyList />}>
        <List className={cardWrapper}>
          <SmallHeader isMe={isMe} publicProfile={publicProfile} />
          <div id="watchlist" role="list">
            {userWatchlist?.map(asset => <Fragment key={asset.id}>
                <ProfileWatchlistItem asset={asset} small={small} />
              </Fragment>)}
          </div>
        </List>
      </SkeletonWrapper>;
  }

  return <SkeletonWrapper pending={Boolean(watchlistPending)} skeleton={<ProfilePositionsSkeleton />} failed={!watchlistPending && !watchlist?.length && <EmptyList />}>
      <ContentCard>
        <LargeHeader isMe={isMe} publicProfile={publicProfile} />
        <div id="watchlist" role="list">
          {userWatchlist?.map(asset => <Fragment key={asset.id}>
              <SpacerV />
              <ProfileWatchlistItem asset={asset} />
            </Fragment>)}
        </div>
      </ContentCard>
    </SkeletonWrapper>;
}

const ShareWatchlistButton = ({
  publicProfile
}: {} & Pick<ProfileIndexProps, 'publicProfile'>) => {
  let [profile] = useGetMyProfile();
  const {
    webShareWatchlist
  } = useFlags();
  const modal = useEphemeralModal();
  const handleOnShare = useCallback((e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    const onImageSelect = (image: string) => {
      modal(null);
      modal(<EditPost isModal files={[image]} initialTitle={`My Watchlist: ${formatOptionExpiration(new Date().toISOString())}`} />);
    };

    profile && modal(<WatchlistShareModal targetUuid={profile.user_uuid} user={userProfileToHydratedProfile(profile)} onImageSelect={onImageSelect} />);
    e.stopPropagation();
  }, [modal, profile]);
  if (!webShareWatchlist || publicProfile && publicProfile?.user_uuid !== profile?.user_uuid) return null;
  return <>
      <SpacerH rem={0.5} />
      <Tooltip tip={'Share your watchlist'}>
        <IconButton onClick={handleOnShare}>
          <Share size={1.25} />
        </IconButton>
      </Tooltip>
    </>;
};

export default Watchlist;
const changeClass = "csv2o0m";
type HeaderProps = {
  isMe: boolean;
} & Pick<ProfileIndexProps, 'publicProfile'>;

const LargeHeader = ({
  isMe,
  publicProfile
}: HeaderProps) => {
  return <FlexRow>
      <TextDisplaySmall role="heading">Watchlist</TextDisplaySmall>
      {isMe && <ShareWatchlistButton publicProfile={publicProfile} />}
      <Expander />
      <TextCaption color={Colors.TextSecondary}>Price</TextCaption>
      <TextCaption color={Colors.TextSecondary} className={changeClass}>
        1d change
      </TextCaption>
    </FlexRow>;
};

const SmallHeader = ({
  isMe,
  publicProfile
}: HeaderProps) => {
  return <FlexRow alignCenter>
      <ListHeader title={'Watchlist'} greyHeader={false} small={true} />
      {isMe && <ShareWatchlistButton publicProfile={publicProfile} />}
      <Expander />
      <TextCaption color={Colors.TextSecondary}>Price</TextCaption>
      <TextCaption color={Colors.TextSecondary} className={changeClass}>
        1d change
      </TextCaption>
      <SpacerH />
    </FlexRow>;
};

const cardWrapper = "cflmz8n";

require("../../../.linaria-cache/packages/oxcart/src/scopes/portfolio/Watchlist.linaria.module.css");