import React from 'react';
import { css } from '@linaria/core';
import { Checkmark } from 'src/theme/NewIcons';
import { UnstyledButton } from 'src/composing/CSButton';
import { Menu, MenuItem } from 'src/components/styles';
import { FlexRow } from 'src/composing/Flex';
import { SpacerH } from 'src/composing/Spacing';
import { ChartData } from '@commonstock/common/src/types';
export type Benchmark = {
  id: string;
  label: string;
  shortLabel: string;
};
type Props = {
  benchmark: Benchmark;
  isOpen: boolean;
  onClick: (benchmark: Benchmark) => void;
};
export const Benchmarks = {
  SP500: {
    id: 'SPY:equity',
    label: 'S&P 500 (SPY)',
    shortLabel: 'S&P 500'
  },
  DowJones: {
    id: 'DIA:equity',
    label: 'Dow Jones (DIA)',
    shortLabel: 'Dow Jones'
  },
  Nasdaq: {
    id: 'QQQ:equity',
    label: 'Nasdaq (QQQ)',
    shortLabel: 'Nasdaq'
  },
  Russell: {
    id: 'IWM:equity',
    label: 'Russell 2k (IWM)',
    shortLabel: 'Russell 2k'
  },
  Bitcoin: {
    id: 'BTC:crypto',
    label: 'Bitcoin (BTC)',
    shortLabel: 'Bitcoin'
  }
};
export function getSelectedBenchmark(currentBenchmark: Benchmark, chartData?: ChartData) {
  if (!chartData) return chartData;
  return { ...chartData,
    benchmark: Array.isArray(chartData.benchmark) ? chartData.benchmark : chartData.benchmark?.[currentBenchmark.id]
  };
}

function PortfolioBenchmarks({
  benchmark,
  isOpen,
  onClick
}: Props) {
  return <Menu hidden={!isOpen} className={menuClass}>
      {Object.values(Benchmarks).map(benchmarkOption => <UnstyledButton key={benchmarkOption.label} onClick={() => onClick(benchmarkOption)}>
          <MenuItem>
            <FlexRow alignCenter>
              {benchmarkOption.label}
              {benchmarkOption.label === benchmark.label && <>
                  <SpacerH rem={0.5} />
                  <Checkmark size={1} />
                </>}
            </FlexRow>
          </MenuItem>
        </UnstyledButton>)}
    </Menu>;
}

export default PortfolioBenchmarks;
const menuClass = "m17e42dy";

require("../../../.linaria-cache/packages/oxcart/src/scopes/portfolio/PortfolioBenchmarks.linaria.module.css");